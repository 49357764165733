.ta-wrapper {
  height: 122vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  overflow-x: hidden;
}

.ta-container {
  position: fixed;
  background-color: #fff;
  border-radius: 10px;
  height: 500px;
  width: 700px;
  padding: 2rem;
  /* right: -50%; */
  margin: auto;
  right: -100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000 !important;
  /* transform: translateX(170%); */
  /* transform: translate(-50%, -50%); */

  /* transition: all 0.7s 0.7s; */
  transition: right 0.3s ease-out;
  /* visibility: hidden; */
  /* display: none; */
}

.dinein-container {
  position: fixed;
  background-color: #fff;
  border-radius: 10px;
  height: 500px;
  overflow-y: scroll;
  width: 80%;
  padding: 2rem;
  /* right: -50%; */
  margin: auto;
  right: -100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000 !important;
  transition: right 0.3s ease-out;
}

.dinein-container p {
  margin: unset !important;
  padding-top: 1.5rem;
}

.currenthall,
.currenttable {
  display: inline-block;
  margin-top: 1rem;
  font-weight: 500;
  font-size: 1rem;
}

.currenthall span,
.currenttable span {
  font-weight: bolder;
}

@media screen and (max-width: 768px) {
  .ta-container {
    width: 80%;
  }

  .dinein-container {
    width: 80%;
  }
}

.show {
  /* margin: auto; */
  /* top: 0; */
  right: 0%;
  /* left: 0; */
  /* bottom: 0; */
  /* left: 0; */
}

.ta-container .ta-table,
.dinein-container .ta-table {
  width: 100%;
  text-align: center;
}

.ta-container .ta-table .ta-itemname,
.dinein-container .ta-table .ta-itemname {
  color: #d14418;
  width: 35%;
  position: relative;
  cursor: pointer;
}

.ta-container .ta-table .ta-itemname .order-tooltip,
.dinein-container .ta-table .ta-itemname .order-tooltip {
  position: absolute;
  background-color: #d14418;
  color: #fff;
  width: auto;
  border-radius: 5px;
  display: none;
  z-index: 1000;
  padding: 1rem;
}

.ta-container .ta-table .ta-itemname,
.dinein-container .ta-table .ta-itemname:hover .order-tooltip {
  display: block;
  width: 70%;
  height: auto;
  top: -20%;
  right: 100%;
}

.ta-container .ta-table .ta-itemname,
.dinein-container .ta-table .ta-waitername {
  width: 20%;
}

.ta-container .ta-table button,
.dinein-container .ta-table button {
  height: 50px;
  margin-top: 1rem;
  /* padding: 1rem; */
  background-color: #d14418;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 5px;
  /* margin: auto; */
}
