.login-btn {
  height: 50px;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #90391e;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin: auto; */
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  display: inline-block;
  width: fit-content;
  height: fit-content;
}
.loading-spinner {
  width: 40px;
  height: 40px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
