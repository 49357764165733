/* body {
  margin: 0;
  padding: 0;
  background-image: url("../images/background.png");
  background-repeat: no-repeat;
  background-size: cover;
} */

.main-wrapper {
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  position: relative;
}

.cashier-wrapper {
  display: flex;
  flex-direction: row;
}

.cashier-wrapper .logo-adds-wrapper {
  flex: 1;
  /* background-color: red; */
  height: auto;
  /* padding: 1.5rem; */
  /* display: none; */
  height: fit-content;
}

.logo-adds-wrapper .logo-wrapper {
  margin: 0 0 1.5rem 0;
}

.adds-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.adds-wrapper button {
  width: 70%;
  margin: auto;
  padding: 0.5rem;
  background-color: #d14418;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 2rem;
}

.general-notes-wrapper,
.special-notes-wrapper {
  text-align: center;
  margin: 0.8rem 0;
}

.general-notes-wrapper .general-notes-title {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
}

.general-notes-wrapper .general-notes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.general-notes-wrapper .general-notes-container span {
  background-color: #fff;
  color: #000;
  border-radius: 30px;
  width: 90%;
  padding: 0.5rem;
  margin: 0.3rem 0;
  font-weight: bolder;
  cursor: pointer;
}

.special-notes-wrapper .special-notes-title {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
}

.special-notes-wrapper .special-notes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.special-notes-wrapper .special-notes-container span {
  background-color: #fff;
  color: #000;
  border-radius: 30px;
  width: 80%;
  padding: 0.5rem;
  margin: 0.3rem 0;
  font-weight: bolder;
  cursor: pointer;
}

.cashier-wrapper .user-notifs-categories-wrapper {
  flex: 1;
  /* background-color: blue; */
  /* height: 1000px; */
  display: flex;
  flex-direction: column;
  height: fit-content;
  /* display: none; */
  /* padding: 1.5rem; */
}

.user-notifs-categories-wrapper .user-notifs-wrapper {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  align-items: flex-end;
}

.user-notifs-categories-wrapper .user-notifs-wrapper .bell-wrapper {
  margin: 1rem 1rem 0 1rem;
}

.user-notifs-categories-wrapper .user-notifs-wrapper .user-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.user-notifs-categories-wrapper .user-notifs-wrapper .userimage {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-right: 0.5rem;
  cursor: pointer;
}

.user-notifs-categories-wrapper .user-notifs-wrapper .user-wrapper .links-list {
  position: absolute;
  width: 120px;
  height: 200px;
  background-color: #d14418;
  border-radius: 5px;
  color: #fff;
  top: 110%;
  left: -20%;
  transition: opacity 0.3s;
  visibility: hidden;
  opacity: 0;
  list-style: none;
  padding: 0;
}

.user-notifs-categories-wrapper
  .user-notifs-wrapper
  .user-wrapper
  .links-list
  li {
  padding: 0.7rem;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  /* margin: 1rem; */
}

.user-notifs-categories-wrapper
  .user-notifs-wrapper
  .user-wrapper
  .links-list.show {
  opacity: 1;
  visibility: visible;
}

.user-wrapper .username-wrapper {
  display: flex;
  flex-direction: column;
}

.user-notifs-categories-wrapper .categories-wrapper {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
}

.user-notifs-categories-wrapper .categories-wrapper .categories-container {
  height: 560px;
  overflow-y: scroll;
}

.categories-wrapper .categories-container .category {
  background-color: #fff;
  width: 90%;
  border-radius: 20px;
  padding: 0.2rem;
  margin: auto;
  margin-top: 0.8rem;
  font-weight: bolder;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.categories-wrapper .categories-container .category .categoryimage {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  max-height: 50px;
  max-width: 50px;
  margin-right: 0.5rem;
}

.cashier-wrapper .main-cashier-wrapper {
  flex: 4;
  /* background-color: green; */
  border-radius: 10px;
  height: fit-content;
  /* display: none; */
}

.cashier-wrapper .main-cashier-wrapper .new-item-wrapper {
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  margin-bottom: 1rem;
}

.cashier-wrapper .main-cashier-wrapper .new-item-wrapper .new-item-table {
  flex: 5;
  overflow-y: hidden;
  overflow-x: hidden;
}

.cashier-wrapper
  .main-cashier-wrapper
  .new-item-wrapper
  .new-item-table
  .thead {
  width: 15%;
  color: #d14418;
  font-weight: bold;
  overflow-y: hidden;
  overflow-x: hidden;
}

tbody {
  border-top: 20px solid transparent;
}

.new-item-table td {
  width: 10%;
  max-width: 10% !important;
  overflow: scroll;
  color: #000;
  font-weight: bold;
  overflow-y: hidden;
  overflow-x: hidden;
}

.cashier-wrapper
  .main-cashier-wrapper
  .new-item-wrapper
  .new-item-table
  .itemname {
  width: 30%;
}

.cashier-wrapper
  .main-cashier-wrapper
  .new-item-wrapper
  .new-item-table
  .itemnotes {
  width: 20%;
}

.cashier-wrapper
  .main-cashier-wrapper
  .new-item-wrapper
  .new-item-table
  .itemqty {
  width: 15%;
  padding-right: 1rem;
}

.cashier-wrapper
  .main-cashier-wrapper
  .new-item-wrapper
  .new-item-table
  .itemprice {
  width: 15%;
  padding-left: 1rem;
}

.cashier-wrapper
  .main-cashier-wrapper
  .new-item-wrapper
  .new-item-table
  .itemtotal {
  width: 15%;
  margin: auto;
}

.cashier-wrapper
  .main-cashier-wrapper
  .new-item-wrapper
  .newitem-btn-container {
  /* flex: 1; */
  /* background-color: blue; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.cashier-wrapper
  .main-cashier-wrapper
  .new-item-wrapper
  .newitem-btn-container
  .newitem-btn {
  width: 80%;
  height: fit-content;
  padding: 0.7rem;
  background-color: #d14418;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
}
.cashier-wrapper
  .main-cashier-wrapper
  .new-item-wrapper
  .newitem-btn-container
  .newitem-btn:active {
  transform: translateY(10px);
}

.cashier-wrapper
  .main-cashier-wrapper
  .new-item-wrapper
  .newitem-btn-container
  .deletenewitem-btn {
  width: auto;
  height: fit-content;
  padding: 0.7rem;
  background-color: red;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
}

.cashier-wrapper
  .main-cashier-wrapper
  .new-item-wrapper
  .newitem-btn-container
  .deletenewitem-btn:active {
  transform: translateY(10px);
}

.cashier-wrapper .order-items-wrapper {
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.order-items-wrapper .order-items-table {
  height: 230px;
  overflow-y: scroll;
}

.paymentModalBtnsWrapper button {
  width: 100%;
}

.error-span {
  color: red;
}

.text-disabled {
  color: #333 !important;
  cursor: unset !important;
}

@media screen and (max-width: 1200px) {
  .order-items-wrapper .order-items-table {
    height: 100px;
    overflow-y: scroll;
  }
}

.order-items-wrapper .order-items-table tr {
  border-bottom: 10px solid transparent;
}

.order-items-wrapper .order-items-table td {
  width: 15%;
}

.order-items-wrapper .order-items-table .orderid {
  width: 5%;
}

.main-cashier-wrapper .order-total-wrapper {
  height: auto;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8272) 0%,
    rgba(255, 255, 255, 0.5544) 100%
  );
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0.7rem;
}

.main-cashier-wrapper .order-total-wrapper .inputs-wrapper {
  flex: 2;
  display: flex;
}

.main-cashier-wrapper .order-total-wrapper .inputs-wrapper span {
  color: #d14418;
  font-weight: bold;
}

.main-cashier-wrapper .order-total-wrapper .inputs-wrapper input {
  background-color: #fff;
  color: #d14418;
}

.main-cashier-wrapper .order-total-wrapper .inputs-wrapper input::placeholder {
  color: #d14418 !important;
  font-weight: bolder;
}

.main-cashier-wrapper .order-total-wrapper .inputs-wrapper div {
  /* flex: 1; */
  margin-right: 1rem;
}

.combineorderid-input-wrapper {
  color: #d14418;
}

.main-cashier-wrapper .order-total-wrapper .paybtn-wrapper {
  flex: 2;
  display: flex;
  justify-content: flex-end;
}

.main-cashier-wrapper .order-total-wrapper .paybtn-wrapper .paybtn {
  width: auto;
  height: 40px;
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d14418;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 3px;
}

.main-cashier-wrapper .options-calculator-wrapper {
  display: flex;
  height: 400px;
  overflow-y: hidden;
}

.disabled-btn {
  background-color: grey !important;
}

@media screen and (max-width: 900px) {
  .main-cashier-wrapper .options-calculator-wrapper {
    flex-direction: column;
  }

  .main-cashier-wrapper .options-calculator-wrapper {
    height: auto;
  }
}

.main-cashier-wrapper .options-calculator-wrapper .dinein-takeaway-wrapper {
  flex: 1;
}

.main-cashier-wrapper .options-calculator-wrapper .orderinfo-wrapper {
  flex: 1;
}

.main-cashier-wrapper .options-calculator-wrapper .dinein-takeaway-wrapper {
  height: auto;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .dinein-takeaway-wrapper
  .dinein-count-wrapper {
  background-color: #fff;
  /* width: 100%; */
  height: 50px;
  border-radius: 5px;
  margin: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem;
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .dinein-takeaway-wrapper
  .dinein-count-wrapper.active,
.main-cashier-wrapper
  .options-calculator-wrapper
  .dinein-takeaway-wrapper
  .takeaway-count-wrapper.active {
  background-color: #ffedc9 !important;
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .dinein-takeaway-wrapper
  .dinein-count-wrapper.active
  .dinein-count,
.main-cashier-wrapper
  .options-calculator-wrapper
  .dinein-takeaway-wrapper
  .takeaway-count-wrapper.active
  .takeaway-count {
  background-color: #ffbc5d !important;
}

@media screen and (max-width: 900px) {
  .main-cashier-wrapper
    .options-calculator-wrapper
    .dinein-takeaway-wrapper
    .dinein-count-wrapper {
    width: 100%;
  }
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .dinein-takeaway-wrapper
  .dinein-count-wrapper
  .dinein-title {
  color: #d14418;
  font-weight: 600;
  padding: 1rem;
  cursor: pointer;
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .dinein-takeaway-wrapper
  .dinein-count-wrapper
  .dinein-count {
  background-color: #ff9338;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .dinein-takeaway-wrapper
  .takeaway-count-wrapper {
  background-color: #fff;
  /* width: 100%; */
  height: 50px;
  border-radius: 5px;
  margin: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem;
}

@media screen and (max-width: 900px) {
  .main-cashier-wrapper
    .options-calculator-wrapper
    .dinein-takeaway-wrapper
    .takeaway-count-wrapper {
    width: 100%;
  }
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .dinein-takeaway-wrapper
  .takeaway-count-wrapper
  .takeaway-title {
  color: #d14418;
  font-weight: 600;
  padding: 1rem;
  cursor: pointer;
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .dinein-takeaway-wrapper
  .takeaway-count-wrapper
  .takeaway-count {
  background-color: #ff9338;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.main-cashier-wrapper .options-calculator-wrapper .calculator-wrapper {
  /* height: 500px; */
  /* background-color: blue; */
  flex: 1;
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .calculator-wrapper
  .calculator-container {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8272) 0%,
    rgba(255, 255, 255, 0.5544) 100%
  );
  border-radius: 20px;
  height: auto;
  padding: 0.5rem;
  color: #d14418;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .calculator-wrapper
  .calculator-container
  .buttons-row {
  /* margin: 1rem; */
  display: block;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: red; */
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .calculator-wrapper
  .calculator-container
  .buttons-row
  .calc-btn {
  background-color: #d14418;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  height: 60px;
  width: 40%;
  padding: 1rem;
  margin: 0.3rem 0.3rem;
  transition: all 0.3s;
  font-weight: bolder;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .calculator-wrapper
  .calculator-container
  .buttons-row
  .calc-btn.abc {
  background-color: #91391e !important;
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .calculator-wrapper
  .calculator-container
  .buttons-row
  .calc-btn:active {
  transform: translateY(10px);
}

.main-cashier-wrapper .options-calculator-wrapper .orderinfo-wrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.main-cashier-wrapper .orderinfo-wrapper .info-container {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8272) 0%,
    rgba(255, 255, 255, 0.5544) 100%
  );
  border-radius: 5px;
  height: auto;
  padding: 0.5rem;
  color: #d14418;
  font-weight: 600;
  margin: 1rem 0 0 1rem;
  text-align: center;
}

.categories-container .spinner-container {
  display: flex;
  margin: auto;
}

.default-productrow {
  display: block;
  width: 100%;
  text-align: center;
  background-color: red;
}

.payment-methods-wrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 1rem;
}

.payment-methods-wrapper .methodswrapper {
  display: flex;
}

.payment-methods-wrapper .paymentmethod {
  width: 100%;
  margin: 1.5rem 0;
}

.payment-methods-wrapper .paymentmethod label {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0 0.5rem;
}

.payment-methods-wrapper button {
  background: #d14418;
  border-radius: 5px;
  height: auto;
  padding: 1rem;
  color: #fff;
  font-weight: 600;
  border: none;
  margin: 1rem 0 0.5rem 0;
}

@media screen and (max-width: 900px) {
  .main-cashier-wrapper .orderinfo-wrapper .info-container {
    margin: 1rem 0 2.5rem 0;
  }
}

.main-cashier-wrapper .orderinfo-wrapper .info-container span {
  text-align: center;
  margin: 0 0.4rem;
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .orderinfo-wrapper
  .buttons-wrapper {
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
}

.main-cashier-wrapper
  .options-calculator-wrapper
  .orderinfo-wrapper
  .buttons-wrapper
  button {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.94) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  border-radius: 5px;
  height: auto;
  padding: 1rem;
  color: #d14418;
  font-weight: 600;
  border: none;
  margin: 1rem 0 0 1rem;
}

.search-wrapper {
  margin-top: 0.5rem;
}

.search-container {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.search-container input {
  visibility: hidden;
  opacity: 0;
  margin: 0 0.5rem;
  transition: all 0.3s;
}

.search-container input.active {
  visibility: visible;
  opacity: 1;
}

tr.disabled {
  background-color: #ccc;
}

.returnedQuantity {
  display: block;
  width: 100px !important;
  margin-left: 1rem;
}
