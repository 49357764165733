.productsMappingWrapper {
  /* width: 100vw; */
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapping-link {
  width: 80%;
  height: fit-content;
  padding: 0.7rem;
  background-color: rgb(231, 231, 24);
  border: 1px solid #fff;
  color: red;
  border-radius: 5px;
  transition: all 0.3s;
}

.approve-btn {
  height: fit-content;
  padding: 0.7rem;
  background-color: darkgreen !important;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
}

.svItemsWrapper,
.fineDineItemsWrapper {
  height: 90%;
  width: 33%;
  background-color: rgb(231, 231, 231);
  margin: 0.5rem;
  border-radius: 5px;
  overflow-y: hidden;
}

.svItemsWrapper.draggingOver {
  background-color: rgb(93, 23, 23);
}

.matchingArea {
  /* background-color: #fff; */
  min-height: 30%;
  max-height: 60%;
  transition: height 0.3s ease-in;
}

.matchingArea.draggingOver {
  height: 50%;
}

.matchingArea .itemsWrapper .item {
  background-color: #fff;
}

.title {
  margin: auto;
  text-align: center;
  margin: 1rem;
  color: #000;
}

.itemsWrapper {
  margin: 1rem;
  height: 80%;
  /* background-color: aqua; */
  overflow-y: scroll;
  padding: 0.5rem 1rem;
}

.itemsWrapper .item {
  background-color: rgb(255, 255, 255);
  height: auto;
  width: 100%;
  border-radius: 15px;
  margin: 0.3rem 0 1rem 0;
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.itemsWrapper .item img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.itemsWrapper .item .itemDataWrapper {
  margin: 1rem;
}

.itemsWrapper .item .itemDataWrapper span {
  color: #000;
}

/* .itemsWrapper .item.dragging {
  background-color: rgb(165, 112, 42);
} */

.itemsWrapper .item p {
  /* margin-right: 1rem; */
  color: rgb(143, 142, 142);
  /* font-size: 1.2rem; */
  /* font-weight: 600; */
  /* padding: 0.5rem; */
}

/* .itemsWrapper .item:last-child {
  background-color: black;
} */
