.modal-overlay {
  height: 100% !important;
  min-height: 122vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  cursor: pointer;
}

.custommodal-content {
  position: absolute;
  height: auto;
  width: 60%;
  padding: 1rem;
  background-color: #fff;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
}

.modal-body .form-group {
  margin: 1rem;
}

.modal-body label,
.modal-body input,
.modal-body select {
  color: #d14418;
  font-weight: bold;
}

/* .modal-body label,
.modal-body input,
.modal-body select:focus {
  color: #d14418;
} */

.noHover {
  border: unset !important;
}

.save-btn {
  width: 30%;
  height: 50px;
  padding: 1rem;
  background-color: #d14418;
  border: 1px solid #fff;
  color: #fff;
  display: block;
  border-radius: 5px;
  margin: auto;
}
