.nav-container {
  /* position: fixed; */

  top: 60px;

  right: 0;

  left: 0;

  z-index: 9;

  /* border-bottom: 1px solid rgb(255 255 255 / 15%); */

  /* box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; */

  /* background-clip: border-box; */

  /* background: rgb(0 0 0 / 20%); */

  /* width: 100%; */
  width: 20%;

  height: auto;

  /* padding: 0px 30px; */

  /* display: flex;
  
    align-items: center;
  
    justify-content: center; */
}

@media screen and (max-width: 1280px) {
  .topbar-logo-header {
    display: none;
  }

  .page-wrapper {
    margin-top: 60px;
  }

  .mobile-toggle-menu {
    display: block;
  }

  .nav-container {
    /* position: fixed; */

    width: 260px;

    right: 0;

    left: -260px;

    z-index: 16;

    border-bottom: 1px solid rgb(255 255 255 / 15%);

    background-clip: border-box;

    /* background: #171717; */

    height: 100%;

    padding: 10px;

    display: flex;

    align-items: flex-start;

    justify-content: flex-start;

    overflow-y: scroll;

    overflow-x: hidden;
  }
}

.topbar-nav {
  /* background: transparent; */

  width: 100%;
  z-index: 20000;
}

.topbar-nav ul {
  padding: 0;

  margin: 0;

  list-style: none;

  /* background: rgba(245, 245, 245, 0.25); */

  z-index: 20000;
}

.topbar-nav .metismenu {
  z-index: 20000;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;

  -webkit-box-orient: vertical;

  -webkit-box-direction: normal;

  -ms-flex-direction: column;

  flex-direction: column;
}

/* .topbar-nav .metismenu > li {
  -webkit-box-flex: 1;

  -ms-flex: 1 1 0%;

  flex: 1 1 0%;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;

  -webkit-box-orient: vertical;

  -webkit-box-direction: normal;

  -ms-flex-direction: column;

  flex-direction: column;

  position: relative;
  background-color: #ffedc9;
  color: #90391e;
  margin: 1rem;
  border: 1px solid #90391e;
} */

.sidebar-link {
  border-bottom: 1px solid #d14418;
  width: 90%;
  padding: 0;
  margin-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 10px;
  font-weight: bolder;
  /* background-color: red; */
}

.sidebar-link.arrow-right {
  position: relative;

  /* display: flex;
  align-items: center; */
}

.sidebar-link.arrow-right a {
  position: absolute;
  top: 25%;
  left: 0;
}

.sidebar-link.arrow-right:after {
  content: "";
  display: inline-block !important;
  width: 0px;
  height: 0px;
  border-left: 25px solid #ffedc9;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  vertical-align: middle;
}

.sidebar-link.arrow-right:before {
  width: 80%;
  height: 50px;
  background: #ffedc9;
  margin: 0;
  padding: 0;
  content: "";
  display: inline-block;
  vertical-align: middle;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
}

.topbar-nav .metismenu a {
  color: #d14418;

  /* position: relative;

  display: flex;

  align-items: center;
  text-decoration: none;
  justify-content: left;

  padding: 10px 15px;

  color: #90391e;

  outline-width: 0;

  transition: all 0.3s ease-out; */
}

.topbar-nav .metismenu ul li {
  background-color: blue;
  position: relative !important;
}

.topbar-nav .metismenu ul li a {
  position: absolute;
}

.topbar-nav .metismenu ul a i {
  /* margin-right: 10px; */
}

.topbar-nav .metismenu a .parent-icon {
  font-size: 24px;

  line-height: 1;
}

.topbar-nav .metismenu a .menu-title {
  margin-left: 10px;
}

.topbar-nav .metismenu a:hover,
.topbar-nav .metismenu a:focus,
.topbar-nav .metismenu a:active {
  color: #ffffff;

  text-decoration: none;

  background: rgb(255 255 255 / 15%);
}

@media (min-width: 1281px) {
  .topbar-nav .metismenu {
    -webkit-box-orient: horizontal;

    -webkit-box-direction: normal;

    -ms-flex-direction: row;

    /* flex-direction: row; */
    flex-direction: column;
  }

  .topbar-nav .metismenu > li {
    -webkit-box-orient: vertical;

    -webkit-box-direction: normal;

    -ms-flex-direction: column;

    flex-direction: column;
  }

  .topbar-nav .metismenu > li > ul {
    /* position: absolute; */

    top: 100%;

    min-width: 100%;

    z-index: 1001;

    /* background: #1e1e1e; */

    /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); */
    transition: all 0.3s ease-out;
  }

  .topbar-nav.is-hoverable .metismenu > li > ul {
    height: auto !important;
    transition: all 0.9s;
  }

  .topbar-nav.is-hoverable .metismenu > li:hover > ul {
    display: block;
    transition: all 0.9s;
  }

  .topbar-nav.is-hoverable .metismenu > li:hover > a.has-arrow:after {
    -webkit-transform: rotate(-135deg);

    transform: rotate(-135deg);
  }
}
